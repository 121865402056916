import React from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from './styles/Typography';


const styles: any = (theme: Theme) => {
  return ({
    root: {
      display: 'flex',
      overflow: 'hidden',
      backgroundColor: theme.palette.secondary.light,
    },

    container: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
      display: 'flex',
      position: 'relative',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 5),
    },
    image: {
      height: 55,
    },
    title: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
    curvyLines: {
      pointerEvents: 'none',
      position: 'absolute',
      top: -180,
    },
    cardItem: {
      paddingLeft: 30,
      paddingRight: 30,
      fontSize: 18,
      paddingBottom: 0
    }
  });
};

type Props = WithStyles<typeof styles>

function ProductValues(props: Props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/assets/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/assets/diet.svg"
                alt="quality-assurance"
              />
              <Typography variant="h6" className={classes.title}>
                Passionate about quality
              </Typography>
              <Typography variant="h5" className={classes.cardItem}>
                {'High quality seasonal food'}
                {', sourced from local providers with care and quality.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/assets/time-management.svg"
                alt="efficient"
              />
              <Typography variant="h6" className={classes.title}>
                Flexible and efficient
              </Typography>
              <Typography variant="h5" className={classes.cardItem}>
                {'We aim to provide a flexibile and efficient service'}
                {', catering for any dietary requirement or taste.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/assets/eco-friendly.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Environmentally friendly
              </Typography>
              <Typography variant="h5" className={classes.cardItem}>
                {'We continually strive to remain environmentally friendly'}
                {', by using responsible food sources and reducing waste.'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

const StyledProductValues = withStyles(styles)(ProductValues);

export default React.memo(StyledProductValues);
