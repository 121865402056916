import React from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from './styles/Typography';
import TextField from './styles/TextField';
import Snackbar from './styles/Snackbar';
import Button from './styles/Button';

const styles: any = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/assets/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

type Props = WithStyles<typeof styles>;
//https://19ru60ayy2.execute-api.us-east-1.amazonaws.com/contact
const emailRegex = new RegExp(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/);

function ProductCTA(props: Props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [submitError, setSubmitError] = React.useState<Error | null>(null);
  const match = email.match(emailRegex)?.filter(Boolean);
  const emailIsValid = Boolean(email) && match?.length === 4;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await fetch('https://19ru60ayy2.execute-api.us-east-1.amazonaws.com/contact', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        email
      })
    }).then(op => op.json()).catch(ex => setSubmitError(ex));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form id="get-in-touch-frm" onSubmit={handleSubmit} className={classes.cardContent}>
              <Typography variant="h2" gutterBottom>
                Get in touch
              </Typography>
              <Typography variant="h5">
                We'll contact you as soon as possible
              </Typography>
              <TextField value={email} onChange={(e) => setEmail(e.target.value)} noBorder className={classes.textField} placeholder="Your email" />
              <Button disabled={!emailIsValid} type="submit" color="primary" variant="contained" className={classes.button}>
                Contact me
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src="/assets/thumb.jpg"
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar

        open={open}
        onClose={handleClose}
        message={submitError ? submitError.message : "Thank you for your interest. We will get back to you soon."}
      />
    </Container>
  );
}

const StyledProductCTA = withStyles(styles)(ProductCTA);

export default React.memo(StyledProductCTA);
