import React from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';


const styles = (theme: Theme) => ({
  markedH2Center: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH3Center: {
    height: 4,
    width: 55,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH4Center: {
    height: 4,
    width: 55,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  markedH6Left: {
    height: 2,
    width: 28,
    display: 'block',
    marginTop: theme.spacing(0.5),
    background: 'currentColor',
  },
});

const variantMapping = {
  h1: 'h1',
  h2: 'h1',
  h3: 'h1',
  h4: 'h1',
  h5: 'h3',
  h6: 'h2',
  subtitle1: 'h3',
};


interface Props {
  marked?: 'center' | 'left',

  children: React.ReactNode;
}

type StyledProps = Props & WithStyles<typeof styles> & TypographyProps;

function Typography(props: StyledProps) {
  
  const { children, classes, marked, variant, color, ...other } = props;
  const className = marked ? `marked${capitalize(variant ?? '') + capitalize(marked)}` : '';
  return (
    <MuiTypography color={color} variantMapping={variantMapping} variant={variant} {...other}>
      {children}
      {marked ? (
        // @ts-ignore
        <span className={classes[className]} />
      ) : null}
    </MuiTypography>
  );
}


export default withStyles(styles)(Typography);
