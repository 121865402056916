import React from 'react';
import './App.css';
import Navbar from './Navbar';
import ProductCategories from './ProductCategories';
import ProductHero from './ProductHero';


import ProductValues from './ProductValues';
import ProductCTA from './ProductCTA';
import ProductSmokingHero from './ProductSmokingHero';
import AppFooter from './AppFooter';
import withRoot from './withRoot';

function App() {
  return (
    <>
      <Navbar />
      <ProductHero />
      <ProductValues />
      <ProductCategories />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </>
  );
}

export default withRoot(App);
