import React from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Button from './styles/Button';
import Typography from './styles/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage =
  '/assets/main.jpg';

const styles = (theme: Theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});


type Props = WithStyles<typeof styles>;

function ProductHero(props: Props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Catering with care
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
        Enjoy your favourite events with great food
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        // @ts-ignore
        component="a"
        href="/#get-in-touch-frm"
      >
        Get in touch
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        Discover the experience
      </Typography>
    </ProductHeroLayout>
  );
}

const StyledProductHero = withStyles(styles)(ProductHero);

export default React.memo(StyledProductHero);