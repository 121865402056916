import React from 'react';
import Container from '@material-ui/core/Container';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from './styles/Typography';

const styles: any = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});


type Props = WithStyles<typeof styles>;


function ProductSmokingHero(props: Props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
     
      <Typography variant="subtitle1" className={classes.link}>
        We are here to help. Get in touch!
      </Typography>
      <img src="/assets/phone-book.svg" className={classes.buoy} alt="phonebook" />
    </Container>
  );
}

const StyledProductSmokingHero =  withStyles(styles)(ProductSmokingHero);

export default React.memo(StyledProductSmokingHero);