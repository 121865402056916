import React from 'react';
import { AppBar, Link, Theme, withStyles, WithStyles } from '@material-ui/core';

import Toolbar, { styles as toolbarStyles } from './styles/Toolbar';


const styles = (theme: Theme) => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  left: {
    flex: 1,
    marginRight: 50
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  center: {
    flex: 1,
    display: 'flex',
    justifyContent: 'centre',
    
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',

  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});


type Props = WithStyles<typeof styles>;

function Navbar(props: Props) {
  const { classes } = props;
  return (
    <div>
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <div className={classes.left} />
        <Link
          variant="h6"
          underline="none"
          color="inherit"
          className={classes.title}
          href="#"
        >
          Five777Events
        </Link>
        <div className={classes.right}>
          {/* <Link
            color="inherit"
            variant="h6"
            underline="none"
            className={classes.rightLink}
            href="/premium-themes/onepirate/sign-in/"
          >
            {'Sign In'}
          </Link>
          <Link
            variant="h6"
            underline="none"
            className={classnames(classes.rightLink, classes.linkSecondary)}
            href="/premium-themes/onepirate/sign-up/"
          >
            {'Sign Up'}
          </Link> */}
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.placeholder} />
  </div>
    // <div>
    //   <AppBar position="fixed">
    //     <Toolbar className={classes.toolbar}>
    //       <div className={classes.left} />
    //       <div className={classes.center}>
    //         <Link
    //           variant="h6"
    //           underline="none"
    //           color="inherit"
    //           className={classes.title}
    //           href="/premium-themes/onepirate/"
    //         >
    //           Five777 Events
    //       </Link>
    //       </div>

    //       <div className={classes.right}>
    //         <Link
    //           color="inherit"
    //           variant="h6"
    //           underline="none"
    //           className={classes.rightLink}
    //           href="/premium-themes/onepirate/sign-in/"
    //         >
    //           {'Sign In'}
    //         </Link>
    //         <Link
    //           variant="h6"
    //           underline="none"
    //           className={classnames(classes.rightLink, classes.linkSecondary)}
    //           href="/premium-themes/onepirate/sign-up/"
    //         >
    //           {'Sign Up'}
    //         </Link>
    //       </div>
    //     </Toolbar>
    //   </AppBar>
    //   <div className={classes.placeholder} />
    // </div>
  );
}


const StyledNavbar = withStyles(styles)(Navbar);

export default React.memo(StyledNavbar);
